import $ from 'jquery';
import document from 'legacy/document.js';
import loggedInAccount from 'loggedInAccount';

var menus = [
	'stepNum_999',
	'stepNum_0',
	'stepNum_995',
	'stepNum_994',
	'stepNum_0_1',
	'stepNum_0_2',
	'stepNum_0_3',
	'stepNum_0_4',
	'stepNum_0_5',
	'stepNum_0_6',
	'stepNum_998',
	'stepNum_1',
	'stepNum_2',
	'stepNum_3',
	'stepNum_4',
	'stepNum_5',
	'stepNum_997',
	'stepNum_993',
	'stepNum_992'
];

var menuCounters = [];

var currentCommandNum = 1;

for (var menuItem in menus) {
	if (!menus.hasOwnProperty(menuItem))
		continue;

	menuCounters[menus[menuItem]] = 0;
}

var shownCalled = false;
var reallyHideMenu = function (id, force, thisCommandNum) {
	// ensure this is the last command
	if (thisCommandNum && currentCommandNum != thisCommandNum) {
		// alert('ignoring command ' + thisCommandNum + ' (' +
		// currentCommandNum + ')');
		return;
	}

	if (force || menuCounters[id] == 0) {
		menuCounters[id] == 0;
		var menu = $('#' + id);
		var toggle = $('#' + id + 'A');
		if (toggle.hasClass('hover')) {
			toggle.removeClass('hover');
			menu.hide();
			//console.log('finished reallyHideMenu (id=' + id + ")");
		}

	}

	var allHidden = true;
	for (var menuItem in menus) {
		if (!menus.hasOwnProperty(menuItem))
			continue;

		if (menuCounters[menus[menuItem]])
			allHidden = false;
	}

	if (allHidden && shownCalled) {
		$(document).trigger('all-hidden.menu');
		shownCalled = false;
	}

};
var hideAllMenus = function () {
	//console.log('calling hideAllMenus');
	for (var menuItem in menus) {
		if (!menus.hasOwnProperty(menuItem))
			continue;

		reallyHideMenu(menus[menuItem], true);
	}
};

var menu = {
	showMenu: function (id) {
		shownCalled = true;

		menuCounters[id] = 1;
		var $menu = $('#' + id);
		$menu.trigger('before-show.menu');
		var obj = $('#' + id + 'A');
		if (!obj.hasClass('hover')) {
			if (!obj)
				return;

			for (var menuItem in menus) {
				if (!menus.hasOwnProperty(menuItem))
					continue;

				var menuName = menus[menuItem];
				if (menuName != id.substr(0, menuName.length)) {
					reallyHideMenu(menus[menuItem], true);
				}
			}

			obj.addClass('hover');

			$menu.show();
			menu.resizeMenu(id);
			//console.log('finished showMenu (id=' + id + ")");
		}
	},
	resizeMenu: function (id) {
		var $menu = $('#' + id);

		if ($menu.length == 0)
			return;

		$menu.css({
			height: 'auto',
			overflowY: 'visible'
		});

		var offset = $menu.offset();
		var menuHeight = $menu.outerHeight();

		var $window = $(window);
		var windowHeight = $window.outerHeight();

		if (menuHeight + offset.top > windowHeight) {
			$menu.css({
				height: (windowHeight - offset.top) + 'px',
				overflowY: 'scroll'
			});
		}

	},
	hideMenu: function (id) {
		menuCounters[id] = 0;
		currentCommandNum++;
		var thisCommandNum = currentCommandNum;
		setTimeout(function () {
			reallyHideMenu(id, false, thisCommandNum);
		}, 500);
	}
};

$(document)
	.on('beforeProcessing.layers beforeLoading.layers', function () {

		hideAllMenus();
	})
	.on('mouseout', '.topNavA,.subMenu', function () {
		var menuId = $(this).closest('td').attr('menu-id');
		if (menuId)
			menu.hideMenu('stepNum_' + menuId);
	})
	.on('mouseover', '.topNavA,.subMenu', function () {
		var menuId = $(this).closest('td').attr('menu-id');
		if (menuId)
			menu.showMenu('stepNum_' + menuId);
	})
	.on('click', '#logoutLink', function () {
		if (loggedInAccount.ssoCode) {
			document.location.href = "/saml/" + loggedInAccount.ssoCode + "?GLO=true&time=" + new Date().getTime();
		} else {
			document.location.href = "/logout.jsp?time=" + new Date().getTime();
		}
	})
	.on('clock-in-out.pendingRefreshed', function (event, model) {
		var pending = model.pending().length;

		var $badge = $('#clockInOut').find('.badge-danger');
		if ($badge.length > 0) {
			if (pending > 0) {
				$badge.text(pending);
			} else {
				$badge.remove();
			}
		}
	});

export default menu;