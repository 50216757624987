import loggedInAccount from 'loggedInAccount';
import misc from 'misc';
import document from 'legacy/document.js';
import json from 'legacy/json';
import * as ko from 'knockout';
import $ from 'jquery';
import _ from 'underscore';
import 'legacy/jquery.browser';
import 'legacy/knockout.stringTemplateEngine';
import {knockoutTemplates} from "legacy/knockout.stringTemplateEngine";
import {bootstrap} from "modules/bootstrap/bootstrap.js";
import {WindowName} from "global/utils/WindowName";

var ajaxLayers = {};
var topBottomPadding = 50;

window.getErrorContent = function (xml) {
	var $errorDiv;
	var $layerScroll;
	if (xml && xml.status == 422) {
		$errorDiv = $("<div class=\"restError\"></div>");
		$errorDiv.append("<h4>Please correct the following errors:</h4>");

		$layerScroll = $("<div class=\"layerScroll\"></div>").appendTo($errorDiv);
		var $list = $("<ul></ul>").appendTo($layerScroll);
		$.each(xml.responseJSON['errorMessages'], function (i, errorMessage) {
			$list.append($("<li></li>").text(errorMessage));
		});

		$errorDiv
			.append("<div class=\"clearfix\"><div class=\"pull-right\"><button class=\"btn btn-default\" onclick=\"removeLayer(getLayerOptions())\">Close</button></div></div>");

		return $errorDiv;
	} else if (xml && xml.status == 401) {
		$errorDiv = $("<div class=\"restError restLoginError\"></div>");
		$errorDiv.append("<h3>Session Expired</h3>");

		$layerScroll = $("<div class=\"layerScroll\"></div>").appendTo($errorDiv);
		var loginUrl = "/login.jsp";
		if (loggedInAccount.ssoCode != null) {
			loginUrl = "/saml/" + loggedInAccount.ssoCode;
		}
		$('<div>Your session has expired.  Please <a href="' + loginUrl + '">log in</a>.</div>').appendTo($layerScroll);
		return $errorDiv;

	} else if (xml && xml.status == 403) {
		$errorDiv = $("<div class=\"restError restLoginError\"></div>");
		$errorDiv.append("<h3>Permission Denied</h3>");

		$layerScroll = $("<div class=\"layerScroll\"></div>").appendTo($errorDiv);
		$('<span>You do not have permission to perform this action.</span>').appendTo($layerScroll);
		return $errorDiv;
	} else if (xml && xml.statusText == 'timeout') {
		$errorDiv = $("<div></div>");
		$errorDiv.append('<div style="color: red; margin: 0 30px 10px 0;">Your request timed out, please try again...</div>');
		$errorDiv
			.append("<div class=\"clearfix\"><div class=\"pull-right\"><button class=\"btn btn-default\" onclick=\"removeLayer(getLayerOptions())\">Close</button></div></div>");
		return $errorDiv;
	} else {
		$errorDiv = $("<div></div>");
		$errorDiv.append('<div style="color: red; margin: 0 30px 10px 0;">An error occurred, please try again...</div>');
		$errorDiv
			.append("<div class=\"clearfix\"><div class=\"pull-right\"><button class=\"btn btn-default\" onclick=\"removeLayer(getLayerOptions())\">Close</button></div></div>");

		return $errorDiv;
	}
};

var ajaxSuccess = function (event, jqXHR) {
	var sentEmailMessages = jqXHR.getResponseHeader('SentEmailMessages');

	var $sentEmailMessages = $('#sentEmailMessages');
	if (!$sentEmailMessages.is("INPUT[type=hidden]")) {
		$sentEmailMessages.remove();
		$sentEmailMessages = null;
	}

	if (!$sentEmailMessages || $sentEmailMessages.length == 0) {
		$sentEmailMessages =
			$('<input/>')
				.attr('type', 'hidden')
				.attr('id', 'sentEmailMessages');
		$('body').append($sentEmailMessages);
	}

	$sentEmailMessages.val(sentEmailMessages);
};

var handleUnintentionalModification = function (ajaxRequest, xml) {

	var options = ajaxRequest.unintentionalModificationOptions;
	if (!options)
		options = {};

	var $content = $("<div class=\"restError unintentionalModification\"></div>");
	$content.append("<h4>Possible Unintentional Modification</h4>");

	var $layerScroll = $("<div class=\"layerScroll\"></div>").appendTo($content);
	var $list = $("<ul></ul>").appendTo($layerScroll);
	$.each(xml.responseJSON['errorMessages'], function (i, errorMessage) {
		$list.append($("<li></li>").text(errorMessage));
	});

	var $cancelButton = $("<button class=\"btn btn-primary\">Cancel</button>")
		.on('click', function () {
			removeLayer(getLayerOptions());
			if ($.isFunction(options.cancel)) {
				options.cancel();
			}
		});
	var $proceedButton = $("<a class=\"proceedAnyways\">Proceed (with caution)</a>");
	$proceedButton.on('click', function () {
		var headers = ajaxRequest.headers;
		if (!headers)
			headers = {};
		headers = $.extend(headers, {'ProceedAnyways': 'true'});
		ajaxRequest.headers = headers;

		removeLayer(getLayerOptions());
		createProcessing();
		$.ajax(ajaxRequest);
	});


	$content
		.append($("<div class=\"clearfix\"></div>").append($("<div class=\"pull-right\"></div>")
			.append($proceedButton)
			.append(" ")
			.append($cancelButton)
		));


	var popupOptions = {
		content: $content,
		layerName: "ErrorLayer"
	};

	var topLayer = getTopLayer();
	if (topLayer && (topLayer.options.processing || topLayer.options.loading)) {
		popupOptions['replaceLayers'] = [
			topLayer.layerName
		]
	}

	popup(popupOptions);

	var $closeButton = $('#layerContainerErrorLayer').find('BUTTON').add('#layerContainerErrorLayer A').first();
	enableSmartFocus($closeButton, $closeButton, $closeButton);

}

var isUnintentionalModification = function (xml) {
	return xml && xml.status == 409;
}

/**
 * Intended to replace both ajaxError and buildRestErrorHandler in quarterly release
 * @param xml
 */
window.ajaxError = function (xml) {
	if (isUnintentionalModification(xml)) {
		return handleUnintentionalModification(this, xml);
	}

	var popupOptions = {
		content: getErrorContent(xml),
		layerName: "ErrorLayer"
	};

	if (xml && xml.status == 401) {
		popupOptions.hideCloseIcon = true;
	}

	var topLayer = getTopLayer();
	if (topLayer && (topLayer.options.processing || topLayer.options.loading)) {
		popupOptions['replaceLayers'] = [
			topLayer.layerName
		]
	}

	popup(popupOptions);

	var $closeButton = $('#layerContainerErrorLayer').find('BUTTON').add('#layerContainerErrorLayer A').first();
	enableSmartFocus($closeButton, $closeButton, $closeButton);
};

window.getWindowOptions = function () {
	var options = $(document).data('windowOptions');
	if (options)
		return options;

	options = {};
	$(document).data('windowOptions', options);
	return options;
};
window.getLayerOptions = function () {
	var layer = getTopLayer();
	if (layer == null)
		return getWindowOptions();

	if (layer.options == undefined)
		layer.options = {};
	return layer.options;
};
window.reloadLayer = function (options) {
	if (typeof options !== 'object')
		options = {};

	var newContent = options.content;
	var layerOptions = $.extend({}, getLayerOptions(), {
		loadingMsg: "Reloading"
	}, options);
	if (newContent)
		layerOptions.content = newContent;
	else
		delete layerOptions.content;

	if (options['hideFirst'])
		removeLayer(layerOptions.layerName);
	else
		layerOptions.replaceLayers.push(layerOptions.layerName);

	popup(layerOptions);
};

/*
 * returns an object for the top layer with the following or undefined
 * if no layers exist { layerName: the name passed in options: options
 * for the givin layer blackout: jQuery pointer to the top layers
 * blackout div container: jQuery pointer to the top layers container
 * div } @example: getLayer('signup').options.onFocus = function() {
 * enableSmartFocus(...) };
 */
window.getTopLayer = function () {
	var topLayer = undefined;
	for (var layerName in ajaxLayers) {
		if (!ajaxLayers.hasOwnProperty(layerName))
			continue;
		if (topLayer == undefined || topLayer.layerNum < ajaxLayers[layerName].layerNum)
			topLayer = ajaxLayers[layerName];

	}
	return topLayer;
};

var postCreateLayer = function ($layerContainer, options) {
	center($layerContainer);
	if (_.isFunction(options.onFocus))
		options.onFocus.call($layerContainer);

	$(window).bind('resize.layer' + options.layerName, function () {
		if (ajaxLayers[options.layerName]) {
			ajaxLayers[options.layerName].blackout.css({
				height: $(window).height() + 'px',
				width: $(window).width() + 'px'
			});
			center(ajaxLayers[options.layerName].container);
		}
	});
};


/**
 * setup an ajax swapout request by blacking out the screen and
 * displaying a loading message. Errors will be automatically handled.
 * If true is returned there is already an ajaxLoading element present
 * in the DOM The ajax request should be cancelled and ajaxLoading must
 * be removed from DOM before proceeding.
 */
window.createLayer = function (layerName) {
	var options;
	if (typeof (layerName) !== 'object') {
		options = {
			'layerName': layerName
		};
	} else {
		options = layerName;
	}

	if (options.layerName === undefined)
		options.layerName = '';

	var numLayers = 1;
	for (layerName in ajaxLayers) {
		if (!$.isArray(options.replaceLayers) || $.inArray(layerName, options.replaceLayers) === -1) {
			numLayers++;
		}

	}

	// catch double click race condition, but allow replacing the
	// current popup with another
	if (ajaxLayers[options.layerName] && (!$.isArray(options.replaceLayers) || $.inArray(options.layerName, options.replaceLayers) === -1)) {
		return false;
	}

	var topLayer = getTopLayer();
	var layerNum = topLayer ? topLayer.layerNum + 1 : 1;

	var $body = $('body');
	var $blackout = $('<div class="blackout" id="blackout' + options.layerName + '"/>').hide().css({
		position: 'fixed',
		zIndex: 100 + (layerNum * 2) - 1,
		top: 0,
		left: 0,
		opacity: .5,
		height: $(window).height() + "px",
		width: $(window).width() + "px",
		backgroundColor: "black"
	}).appendTo($body);

	var containerClassString = (options.containerClass ? ' class="layerContainer ' + options.containerClass + '"' : 'class="layerContainer"');
	var $layerContainer = $('<div ' + containerClassString + ' id="layerContainer' + options.layerName + '"/>').css({
		position: $.browser['msie'] && $.browser.version.slice(0, 1) == 6 ? 'absolute' : 'fixed',
		display: 'none',
		top: 0,
		'max-width': '80%',
		zIndex: (100 + layerNum * 2)
	});

	var $layerContainerTable = $('<table class="layerContainerTable"></table>').appendTo($layerContainer);
	var $layerContainerRow = $('<tr/>').appendTo($layerContainerTable);
	var $layerContainerCell = $('<td class="layerContainerTD"/>').appendTo($layerContainerRow);
	var $layer = $('<div class="layer" id="layer' + options.layerName + '"/>').attr('uid', new Date().getTime()).appendTo($layerContainerCell);

	var existingLayer = ajaxLayers[options.layerName];
	delete ajaxLayers[options.layerName];

	ajaxLayers[options.layerName] = {
		layerName: options.layerName,
		blackout: $blackout,
		container: $layerContainer,
		options: options
	};

	ajaxLayers[options.layerName].layerNum = layerNum;

	var $clickToClose = $('<a href="javascript:void(0);" class="closeLayer" id="close' + options.layerName + '">&times;</a>').appendTo(
		$layerContainerCell);
	$clickToClose.click({
		options: options
	}, function (e) {
		removeLayer(e.data.options.layerName);
		return false;
	});
	if (options.hideCloseIcon)
		$clickToClose.hide();

	if (options.replaceLayers && $.isArray(options.replaceLayers)) {
		for (var i in options.replaceLayers) {
			if (!options.replaceLayers.hasOwnProperty(i))
				continue;
			layerName = options.replaceLayers[i];
			if (layerName == options.layerName) {
				if (existingLayer) {
					removeLayer(existingLayer);
				}
			} else {
				removeLayer({
					layerName: layerName
				});
			}
		}
	}

	$body.append($layerContainer);
	if (typeof options.html !== 'object') {
		$layer.html(options.content);
	} else {
		$layer.append(options.content);
	}

	updateBlackoutOpacity();
	$blackout.show();

	$layerContainer.show();

	if (options.module == null) {
		postCreateLayer($layerContainer, options);
	}

	return ajaxLayers[options.layerName];
};

window.removeLayer = function (layerName) {
	var options;
	var layer;
	if (typeof (layerName) === 'object') {
		options = layerName;
	} else {
		options = {
			'layerName': layerName
		};
	}

	if (options.all) {
		layer = getTopLayer();
		while (layer !== undefined) {
			removeLayer(layer.layerName);
			layer = getTopLayer();
		}

		return;
	}

	if (options.layerName === undefined)
		options.layerName = '';

	if (ajaxLayers[options.layerName] !== undefined) {
		var replacing = !!options.blackout;
		layer = replacing ? options : ajaxLayers[options.layerName];
		if (layer.options.onClose) {
			layer.options.onClose();
		}

		if (layer.container.length > 0) {
			ko.cleanNode(layer.container[0]);
		}

		layer.container.add(layer.blackout).remove();

		$('body').tipsy.revalidate();

		if (!replacing) {
			delete ajaxLayers[options.layerName];
		}

		updateBlackoutOpacity();

		$(window).unbind('resize.layer' + options.layerName);

		// add logic to call onFocus function of top most layer, falling
		// back to window.onFocus
		var prevLayer = getTopLayer();
		if (prevLayer) {
			if (prevLayer.options.onFocus && typeof (prevLayer.options.onFocus) == 'function') {
				prevLayer.options.onFocus.call(prevLayer.container);
			}
		} else {
			var windowOptions = getWindowOptions();
			if (_.isFunction(windowOptions.onFocus)) {
				windowOptions.onFocus.call(window);
			}
		}
	}
};


var updateBlackoutOpacity = function () {
	var numLayers = _.size(_.keys(ajaxLayers));
	var layerNum = 1;
	for (var layerName in ajaxLayers) {
		var layer = ajaxLayers[layerName];

		var opacity = .75 - ((numLayers - layerNum + 1) * .25);
		if (opacity < .1) {
			opacity = .1;
		}
		// var opacity = numLayers == layer.num ? .5 : 0;
		layer.blackout.css({
			opacity: opacity
		});
		layerNum++;
	}

};

/*
 * successRedirectTo, can be a url, or a set of options with these keys:
 * successJS: JS to execute buttonText: test for the button
 */
window.showSuccess = function (successMessage, successRedirectTo) {
	var options = {};
	if (typeof (successRedirectTo) === 'object') {
		options = successRedirectTo;
	} else if (successRedirectTo != null) {
		if ($.isFunction(successRedirectTo)) {
			options.successJS = successRedirectTo;
		} else {
			options.successJS = "document.location.href='" + successRedirectTo + "';";
		}
	}

	// skip the popup if there is no message
	if (successMessage === null) {
		if ($.isFunction(options.successJS)) {
			options.successJS();
		} else {
			eval(options.successJS);
		}
		return;
	}

	if (!options.buttonText)
		options.buttonText = 'Continue';
	if (!options.successJS)
		options.successJS = 'hideProcessing()';

	options.hideButton = options.hideButton === true

	var $contents = $('<div id="ajaxSuccess" class="ajaxSuccess">'
		+ '<p>'
		+ successMessage
		+ '</p>'
		+ '<div>'
		+ (options.hideButton ? '' : '<div class="clearfix"><div class="pull-right"><button id="ajaxSuccessContinue" class="btn btn-primary">'
			+ options.buttonText + '</button></div></div>')
		+ '</div>' + '</div>');

	var $button = $contents.find('#ajaxSuccessContinue');
	$button.click(function () {
		if ($.isFunction(options.successJS)) {
			options.successJS();
		} else {
			eval(options.successJS);
		}

		return false;
	});

	var popupOptions = $.extend('{}', {
		layerName: "Processing",
		replaceLayers: [
			"Processing"
		],
		content: $contents,
		hideCloseIcon: true,
		onFocus: function () {
			enableSmartFocus($button, $button, $button);
		}
	}, options);

	popup(popupOptions);

	return false;

};

window.showErrors = function (errorMessagesJson, errorRedirectTo) {
	var options = {};
	if (typeof (errorRedirectTo) === 'object') {
		options = errorRedirectTo;
	} else if (errorRedirectTo != null) {
		options.errorJS = 'document.location.href=\'' + errorRedirectTo + '\'';
	}

	if (!options.errorHeader)
		options.errorHeader = 'Please correct the following errors:';

	var errors;
	if ($.isArray(errorMessagesJson)) {
		errors = errorMessagesJson;
	} else {
		errors = json.parse(errorMessagesJson);
	}
	var errorStr = '<div id="ajaxErrorHeader">' + options.errorHeader + '</div>';
	for (var i in errors) {
		if (!errors.hasOwnProperty(i))
			continue;
		var errorMessage = errors[i];
		errorStr += '<div class="ajaxErrorMsg">' + errorMessage + '</div>\n';
	}

	if (!options.buttonText)
		options.buttonText = 'Continue';
	if (!options.errorJS)
		options.errorJS = 'hideProcessing()';

	var $contents = $('<div id="ajaxError">' + errorStr + '<div>'
		+ '<div class="clearfix"><a href="#" class="btn btn-primary pull-right" id="ajaxErrorContinue">' + options.buttonText + '</a></div>'
		+ '</div>' + '</div>' + '');

	var $button = $contents.find('#ajaxErrorContinue');
	$button.click(function () {
		eval(options.errorJS);
		return false;
	});

	var popupOptions = $.extend('{}', {
		layerName: "Processing",
		replaceLayers: [
			"Processing"
		],
		content: $contents,
		hideCloseIcon: true,
		onFocus: function () {
			enableSmartFocus($button, $button, $button);
		}
	}, options);

	popup(popupOptions);

	return false;
};

var center = function ($obj) {
	var $window = $(window);

	var windowHeight = $window.height();
	var windowWidth = $window.width();

	var $layerScroll = $obj.find('.layerScroll:visible');
	var layerScrollScrollTop = $layerScroll.scrollTop();
	var doPopupScroll = true, hasScrollX = false, hasScrollY = false;
	if ($layerScroll.length == 0) {
		$layerScroll = $obj;
		doPopupScroll = false;

	}

	$obj.css({
		top: 0,
		left: 0
	});

	$layerScroll.css({
		width: 'auto',
		height: 'auto',
		overflowX: 'hidden',
		overflowY: 'hidden'
	});
	$layerScroll.removeClass('layerScrollUnused');

	// var layerScrollOldHeight = $layerScroll.outerHeight(true);
	// var layerScrollOldWidth = $layerScroll.outerWidth(true);

	var layerScrollOffset = $layerScroll.offset();

	var objHeight;
	var objWidth;
	if (doPopupScroll) {
		objHeight = $obj.outerHeight(true);
		var layerScrollHeight = $layerScroll.outerHeight(false);

		// var layerScrollOldHeight = $layerScroll.outerHeight();
		var layerScrollHeightPadding = layerScrollHeight - $layerScroll.height();

		if (objHeight > (windowHeight - (topBottomPadding * 2))) {
			// $(window).height() - $('.layerScroll').offset().top -
			// ($('.layerContainer').outerHeight() -
			// $('.layerScroll').offset().top -
			// $('.layerScroll').outerHeight())
			hasScrollY = true;

			var layerScrollNewHeight = windowHeight - layerScrollHeightPadding - layerScrollOffset.top
				- (objHeight - layerScrollOffset.top - layerScrollHeight) - (topBottomPadding * 2);
			$layerScroll.css({
				paddingRight: (scrollbarWidth() < 10 ? 10 : scrollbarWidth()) + 'px', // add
				// 10px
				// for
				// the
				// scrollbar
				// itself
				height: layerScrollNewHeight + 'px',
				overflowY: 'auto'
			}).scrollTop(layerScrollScrollTop);
		}

		objWidth = $obj.outerWidth(true);
		var layerScrollWidth = $layerScroll.outerWidth(true);

		// padding calculation assumes that layerScroll will have equal
		// padding and margins between layerScroll and obj
		var layerScrollWidthPadding = ($layerScroll.offset().left - $obj.offset().left) * 2;
		var layerScrollNewWidth = Math.ceil(objWidth - layerScrollWidthPadding);
		if (layerScrollWidth > layerScrollNewWidth) {
			$layerScroll.css({
				width: layerScrollNewWidth + 'px',
				overflowX: 'auto'
			});

			hasScrollX = true;
		}
	} else {
		objHeight = $obj.outerHeight(true);

		objWidth = $obj.outerWidth(true);
	}

	var top = ((windowHeight - objHeight) / 2);
	if (top < topBottomPadding)
		top = topBottomPadding;

	var left = (windowWidth - objWidth) / 2;
	if (left < 0)
		left = 0;

	$obj.css({
		"top": top + 'px',
		"left": left + 'px'
	});

	var $layerScrollSmoothScrollTop;
	var $layerScrollSmoothScrollBottom;
	if (doPopupScroll && hasScrollY) {
		layerScrollOffset = $layerScroll.offset();

		$layerScrollSmoothScrollTop = $layerScroll.find('.layerScrollSmoothScrollTop');
		if ($layerScrollSmoothScrollTop.length == 0) {
			$layerScrollSmoothScrollTop = $('<div class="layerScrollSmoothScrollTop"/>').prependTo($layerScroll);
		}

		$layerScrollSmoothScrollBottom = $layerScroll.find('.layerScrollSmoothScrollBottom');
		if ($layerScrollSmoothScrollBottom.length == 0) {
			$layerScrollSmoothScrollBottom = $('<div class="layerScrollSmoothScrollBottom"/>').prependTo($layerScroll);
		}

		var $layerScrollWidthDetector = $layerScroll.find('.layerScrollWidthDetector');
		if ($layerScrollWidthDetector.length == 0) {
			$layerScrollWidthDetector = $('<div class="layerScrollWidthDetector"/>').prependTo($layerScroll);
		}

		var layerScrollDetectorWidth = $layerScrollWidthDetector.outerWidth();
		$layerScrollSmoothScrollTop.css({
			position: 'fixed',
			top: layerScrollOffset.top,
			left: layerScrollOffset.left,
			width: layerScrollDetectorWidth + 'px'
		});
		$layerScrollSmoothScrollBottom.css({
			position: 'fixed',
			top: layerScrollOffset.top + $layerScroll.innerHeight() - $layerScrollSmoothScrollBottom.height() - (hasScrollX ? (scrollbarWidth()) : 0),
			left: layerScrollOffset.left,
			width: layerScrollDetectorWidth + 'px'
		});

	} else if (doPopupScroll) {
		$layerScrollSmoothScrollTop = $layerScroll.find('.layerScrollSmoothScrollTop');
		if ($layerScrollSmoothScrollTop.length > 0) {
			$layerScrollSmoothScrollTop.css({
				left: '-10000px'
			});
		}
		$layerScrollSmoothScrollBottom = $layerScroll.find('.layerScrollSmoothScrollBottom');
		if ($layerScrollSmoothScrollBottom.length > 0) {
			$layerScrollSmoothScrollBottom.css({
				left: '-10000px'
			});
		}

	}

};

var _scrollbarWidth = undefined;
var scrollbarWidth = function () {
	if (_scrollbarWidth === undefined) {
		var div = $('<div style="width:50px;height:50px;overflow:hidden;position:absolute;top:-200px;left:-200px;"><div style="height:100px;"></div>');
		// Append our div, do our calculation and then remove it
		$('body').append(div);
		var w1 = $('div', div).innerWidth();
		div.css('overflow-y', 'scroll');
		var w2 = $('div', div).innerWidth();
		$(div).remove();
		_scrollbarWidth = (w1 - w2);
	}
	return _scrollbarWidth;
};

var moduleNameToLayerName = function (name) {
	if (name.lastIndexOf('/') != -1) {
		name = name.substring(name.lastIndexOf('/') + 1)
	}

	return _.reduce(name.split('-'), function (result, part) {
		if (part.length < 2)
			return part.toUpperCase();
		else
			return result + part.substring(0, 1).toUpperCase() + part.substring(1);
	}, '');
};

window.popup = function (url, layerName, options) {
	if (typeof (options) !== 'object')
		options = {};

	if (typeof (url) === 'object')
		options = url;
	else if (typeof (url) === 'string')
		options.url = url;

	if (typeof (layerName) === 'object')
		options = layerName;
	else if (typeof (layerName) === 'string')
		options.layerName = layerName;


	if (options.layerName === undefined && options.module)
		options.layerName = moduleNameToLayerName(options.module);
	else if (options.layerName === undefined)
		options.layerName = '';


	if (options.loadingMsg === undefined)
		options.loadingMsg = 'loading';

	$(document).trigger('beforeLoading.layers', [options]);

	if (options.content)
		return createLayer(options);


	var loadingLayer = createLayer({
		layerName: options.layerName + 'Loading',
		loading: true,
		containerClass: 'loadingContainer',
		content: '<div class="loadingLayer">' + options.loadingMsg + ' <a href="javascript:removeLayer(\'' + options.layerName
			+ 'Loading\')">close</a></div>'
	});
	if (!loadingLayer)
		return;

	if (options.module) {
		// Allow static model to be passed
		if (options.module.model && !options.module.initialize) {
			options.module.initialize = function () {
				return $.Deferred().resolve(options.module.model);
			}
		}

		var loadModule = function (module) {
			if (module['__esModule'] === true && module['module']) {
				// unwrap esModule
				module = module['module'];
			}


			module.initialize(options.data).done(function (model) {
				if (model === false)
					return;

				knockoutTemplates[options.layerName] = module.template;

				options = $.extend({
					replaceLayers: [],
					content: $('<div/>').attr('data-bind', 'template: "' + options.layerName + '"')
				}, options);

				options.replaceLayers.push(options.layerName + 'Loading');
				options.replaceLayers.push(options.layerName);
				var newLayer = createLayer(options);

				module.layer = newLayer;
				ko.applyBindings(model, newLayer.container[0]);
				postCreateLayer(newLayer.container, options);

				if (module.postInitialize) {
					module.postInitialize();
				}
			});

		};

		if (_.isObject(options.module)) {
			loadModule(options.module);
		} else {
			bootstrap.loadModule(options.module).then(({default: module}) => {
				loadModule(module);
			});
		}
	} else {

		var ajaxOptions = {
			url: misc.randomizeUrl(options.url),
			type: 'POST',
			layerOptions: options,
			success: function (data) {
				if (data == null || data.length === 0) {
					return this.error(data);
				}
				ajaxOptions.layerOptions = $.extend({}, {
					replaceLayers: []
				}, {
					content: data
				}, ajaxOptions.layerOptions);
				ajaxOptions.layerOptions.replaceLayers.push(options.layerName + 'Loading');
				ajaxOptions.layerOptions.replaceLayers.push(options.layerName);
				createLayer(ajaxOptions.layerOptions);

				$(document).trigger('added.layers', [
					ajaxOptions.layerOptions
				]);

				processAjaxOnload();
			}

		};

		if (options.timeout) {
			ajaxOptions['timeout'] = options.timeout;
		}
		if (options.data) {
			ajaxOptions['data'] = options.data;
		}

		// options can contain a require attribute specifying an array of resources that should be loading prior
		// to loading the new layer via ajax.  If the first resource returns an object with an initialize function
		// then it is called after ajax has been sucessfully loaded
		// this is a bit magical for more liking, but I couldn't think of a better way to a callback once
		// the layer is loaded.
		if (options['moduleDependency']) {
			bootstrap.loadModule(options['moduleDependency']).then(({default: dep}) => {
				$.ajax(ajaxOptions).done(() => {
					if (_.isObject(dep) && dep.hasOwnProperty('initialize') && _.isFunction(dep['initialize'])) {
						dep.initialize();
					}
				});
			});
		} else {
			$.ajax(ajaxOptions);
		}
	}
}

window.hidePopup = function (layerName) {
	if (layerName === undefined)
		layerName = '';

	removeLayer(layerName);
};
/**
 * returns true on success
 */
window.process = function (url, data, options) {
	if (typeof (url) === 'object') {
		options = url;
	} else {
		if (typeof (options) !== 'object')
			options = {};

		options.url = url;

		if (data != undefined)
			options.data = data
	}

	var processingLayer = createProcessing(options);

	if (processingLayer === false)
		return false;

	if (!options.success || typeof (options.success) !== 'function') {
		options.success = function (data) {
			if (!data || data.length === 0) {
				return this.error(data);
			}

			if (data.indexOf('<!--content-->') != -1) {
				createLayer({
					layerName: options.layerName,
					content: data
				});

			} else if (data.indexOf('<!--success-->') != -1) {
				$('BODY').append(data);
				processAjaxOnload();
			} else {
				this.error();
			}
		}
	}

	if (!options.type) {
		options.type = options.data === undefined ? 'GET' : 'POST'
	}

	var ajaxOptions = {
		url: misc.randomizeUrl(options.url),
		type: options.type,
		data: options.data,
		success: options.success,
		unintentionalModificationOptions: options.unintentionalModificationOptions
	};

	if (options.contentType)
		ajaxOptions.contentType = options.contentType;

	$.ajax(ajaxOptions);

	return true;
};

window.createProcessing = function (options) {
	if (typeof (options) !== 'object')
		options = {};

	if (options.processingMsg === undefined)
		options.processingMsg = 'processing';

	if (options.layerName === undefined)
		options.layerName = 'Processing';

	$(document).trigger('beforeProcessing.layers', [options]);
	return createLayer({
		layerName: options.layerName,
		processing: true,
		hideCloseIcon: true,
		replaceLayers: options.replaceLayers,
		content: '<div class="processingLayer">' + options.processingMsg + '</div>'
	});
};

window.hideProcessing = function () {
	removeLayer({
		layerName: 'Processing'
	});
};

window.processAjaxOnload = function () {
	if (typeof (ajaxOnload) === 'function') {
		ajaxOnload();
		ajaxOnload = undefined;
	}
};

$.ajaxSetup({
	traditional: true,
	error: window.ajaxError,
	beforeSend: (jqXHR) => {
		jqXHR.setRequestHeader('Window-Name', WindowName.get());
	},
	timeout: 30 * 1000
});
$(document).ajaxSuccess(ajaxSuccess);

export default {
	ajaxLayers: ajaxLayers,
	createProcessing: window.createProcessing,
	hideProcessing: window.hideProcessing,
	getLayerOptions: window.getLayerOptions,
	center: center,
	popup: window.popup,
	process: window.process,
	hidePopup: window.hidePopup,
	removeLayer: window.removeLayer,
	ajaxError: window.ajaxError,
	authError: function () {
		return window.ajaxError({
			status: 401,
			statusText: ''
		})
	},
	showSuccess: window.showSuccess,
	showErrors: window.showErrors,
	removeTopLayer: function () {
		var layer = getTopLayer();
		if (layer != null)
			removeLayer(layer.layerName);
	},
	isShowingPopup: function (layerName) {
		return ajaxLayers.hasOwnProperty(layerName);
	},
	isUnintentionalModification: isUnintentionalModification
}