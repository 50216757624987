import _ from 'underscore';
import countyModel from 'global/models/county.js';
import county from 'county';

export const Counties = [];

export function defineCounties(data) {
	var counties = _.isEmpty(data) ? (county == null ? [] : [county]) : _.map(data, function (county) {
		return new countyModel(county)
	});

	Counties.push.apply(Counties, counties);
}

export default Counties;