import $ from "jquery";
import _ from "underscore";
import * as ko from 'knockout';
import misc from "misc";
import format from "format";
import "legacy/select2-4.0.13/js/select2.js";


ko.bindingHandlers.datepicker = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
	},
	update: function (element, valueAccessor) {
		$(element).datepicker(valueAccessor());
	}
};

ko.bindingHandlers.jqueryAppend = {
	init: function (element, valueAccessor) {
	},
	update: function (element, valueAccessor, allBindings) {
		var $element = $(element);
		var contents = ko.unwrap(valueAccessor());
		$element.empty().append(contents)
	}
};

//Here's a custom Knockout binding that makes elements shown/hidden via jQuery's fadeIn()/fadeOut() methods
//Could be stored in a separate utility library
ko.bindingHandlers.fadeVisible = {
	init: function (element, valueAccessor) {
		// Initially set the element to be instantly visible/hidden depending on the value
		var value = valueAccessor();
		$(element).toggle(ko.utils.unwrapObservable(value)); // Use "unwrapObservable" so we can handle values that may or may not be observable
	},
	update: function (element, valueAccessor) {
		// Whenever the value subsequently changes, slowly fade the element in or out
		var value = valueAccessor();
		ko.utils.unwrapObservable(value) ? $(element).fadeIn() : $(element).fadeOut();
	}
};

// Here's a custom Knockout binding that calls buttonSet on the assigned element.
// The accessor is evaluated and the result is ignored.  When subscriptions are notified the buttonSet will be
// updated
ko.bindingHandlers.buttonSet = {
	initial: false,
	update: function (element, valueAccessor) {
		var value = valueAccessor();
		ko.utils.unwrapObservable(value);

		if (!this.initial) {
			$(element).buttonset();
			this.initial = false;
		} else {
			$(element).buttonset("refresh");
		}
	}
};

ko.bindingHandlers.fileinput = {
	update: function (element, valueAccessor) {
		var options = valueAccessor();


		var $element = $(element);
		$element.fileinput(options);

		if (options['autoUpload']) {
			$element.on("filebatchselected", function () {
				$element.fileinput("upload");
			});
		}
		if (options['onFileUploaded'] && _.isFunction(options['onFileUploaded'])) {
			$element.on("fileuploaded", function () {
				options['onFileUploaded'].apply($element, arguments);
			});
		}

		if (options['onFileSuccessRemove'] && _.isFunction(options['onFileSuccessRemove'])) {
			$element.on("filesuccessremove", function () {
				options['onFileSuccessRemove'].apply($element, arguments);
			});
		}

		if (options['onFileRemoved'] && _.isFunction(options['onFileRemoved'])) {
			$element.on("fileremoved", function () {
				options['onFileRemoved'].apply($element, arguments);
			});
		}

		if (options['onFileUploadError'] && _.isFunction(options['onFileUploadError'])) {
			$element.on("fileuploaderror", function () {
				options['onFileUploadError'].apply($element, arguments);
			});
		}


	}
};

ko.bindingHandlers.select2 = {
	init: function (element, valueAccessor) {
	},
	update: function (element, valueAccessor, allBindings) {
		var $element = $(element);
		// necessary do to bug in select2 4.0.3
		if ($element.prop('multiple') == false) {
			if ($element.find('option:not(:disabled):selected').length == 0) {
				$element
					.find('option:not(:disabled)').first().prop('selected', true)
					.trigger('change');
			}
		}

		// addSubscription to options and foreach
		ko.unwrap(allBindings.get('options')) || ko.unwrap(allBindings.get('foreach'));

		if ($element.data('select2')) {
			{
				// make sure $element select has been populated with values from selectedOptions.
				// NOTE: I'm unsure when this isn't done automatically from knockout.
				var selectedOptions = allBindings.get('selectedOptions');
				if (ko.isObservable(selectedOptions)) {
					$element.val(selectedOptions.peek());
				}
			}
			// need to destroy and recreate per bug in select2 4.0.3 https://github.com/select2/select2/issues/3347
			$element.data('select2').destroy();
			$element.select2(valueAccessor());
		} else {
			{
				// setup dependency to propagate changes in value to select2 through 'change' event
				var initial = true;
				ko.dependencyDetection.ignore(function () {
					ko.computed(function () {
						ko.unwrap(allBindings.get('selectedOptions')) || ko.unwrap(allBindings.get('value'));
						if (initial) {
							initial = false;
						} else {
							$element.trigger('change.select2');
						}
					});
				});
			}

			$element.select2(valueAccessor());
		}
	}
};

ko.bindingHandlers.multiselect = {
	update: function (element, valueAccessor) {
		$(element).multiselect(valueAccessor());
	}
};

ko.bindingHandlers.numeric = {
	init: function (element, valueAccessor) {
		if (valueAccessor()) {
			$(element).numeric(".", true, null, true)
		}
	},
	update: function () {
	}
};

ko.bindingHandlers.floatFormat = {
	init: function (element, valueAccessor) {
		if (valueAccessor()) {
			$(element).on('blur', function () {
				format.customFloatFormat($(this));
			})
		}
	},
	update: function () {
	}
};

ko.bindingHandlers.qtip = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
	},
	update: function (element, valueAccessor) {
		var qtipOptions = valueAccessor();
		var $this = $(element);
		qtipOptions = $.extend(true, misc.getDefaultQtipOptions(), qtipOptions);

		if ($this.closest('.subMenu').length != 0) {
			if (qtipOptions.style && _.isObject(qtipOptions.style))
				qtipOptions.style['classes'] += " qtip-over-menu";
			else if (qtipOptions.style)
				qtipOptions.style += " qtip-over-menu";
		}

		if ($this.qtip('api'))
			return;

		$this.qtip(qtipOptions);
	}
};

ko.bindingHandlers.option = {
	update: function (element, valueAccessor) {
		var value = ko.utils.unwrapObservable(valueAccessor());
		ko.selectExtensions.writeValue(element, value);
	}
};

ko.bindingHandlers.popover = {
	init: function (element, valueAccessor, allBindingsAccessor, viewModel, bindingContext) {
	},
	update: function (element, valueAccessor) {
		var options = valueAccessor();
		var $this = $(element);

		if (options === false) {
			$this.popover('destroy');
		} else {
			$this.popover(options);
		}
	}
};
