import _ from 'underscore';
import * as ko from 'knockout';


//define a template source that tries to key into an object first to find a template string
export const knockoutTemplates = {};

var data = {},
	engine = new ko.nativeTemplateEngine();

ko.templateSources.stringTemplate = function (template) {
	this.templateName = template;
};

ko.utils.extend(ko.templateSources.stringTemplate.prototype, {
	data: function (key, value) {
		data[this.templateName] = data[this.templateName] || {};

		if (arguments.length === 1) {
			return data[this.templateName][key];
		}

		data[this.templateName][key] = value;
	},
	text: function (value) {
		if (arguments.length === 0) {
			var template = knockoutTemplates[this.templateName];

			if (typeof (template) === "undefined") {
				throw Error("Template not found: " + this.templateName);
			}

			return template;
		}

		knockoutTemplates[this.templateName] = value;
	}
});

engine.renderTemplateSource = function (templateSource, bindingContext) {
	// Precompile and cache the templates for efficiency
	var template = templateSource['text']();
	if (_.isFunction(template)) {
		var model = _.isObject(bindingContext['$data']) ? _.extend({}, bindingContext, bindingContext['$data']) : bindingContext;
		var renderedMarkup = template(model);
		return ko.utils.parseHtmlFragment(renderedMarkup);
	} else {
		// fallback to nativeTemplateEngine
		return ko.nativeTemplateEngine.instance.renderTemplateSource(templateSource, bindingContext);
	}
};

engine.makeTemplateSource = function (template, doc) {
	var elem;
	if (typeof template === "string") {
		elem = (doc || document).getElementById(template);

		if (elem) {
			return new ko.templateSources.domElement(elem);
		}

		return new ko.templateSources.stringTemplate(template);
	} else if (template && (template.nodeType == 1) || (template.nodeType == 8)) {
		return new ko.templateSources.anonymousTemplate(template);
	}
};


//make this new template engine our default engine
ko.setTemplateEngine(engine);
