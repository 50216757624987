import loggedInAccount from 'loggedInAccount';
import layers from "layers";

var self = {
	refresh: function () {
		var $hiddenFrame = $('#sso-refresh');
		if ($hiddenFrame.length > 0)
			return;

		$hiddenFrame = $('<iframe/>')
			.attr("src", "/saml/" + loggedInAccount.ssoCode + "?refresh=true")
			.attr("width", "0")
			.attr("height", "0")
		;

		$('body').append($hiddenFrame)

	},
	schedule: function () {
		if (loggedInAccount.sessionExpires > 0) {
			var runInMillis = loggedInAccount.sessionExpires - (1 * 1000 * 30);

			setTimeout(self.refresh, runInMillis);
		}
	},
	cleanup: function () {
		var $hiddenFrame = $('#sso-refresh');
		$hiddenFrame.detach();

	},
	authenticated: function (sessionExpires) {
		//console.log("updating sessionExpires " + sessionExpires);
		loggedInAccount.sessionExpires = sessionExpires;
		self.schedule();
		self.cleanup();
	},
	failed: function () {
		layers.authError();
	},
};


export const SsoRefreshScheduler = self;
window.SsoRefreshScheduler = SsoRefreshScheduler
