import {ReleaseNotification, SubscriptionManager} from "../ws/StompClient";

class ReleaseManagerClazz {
	static readonly CurrentRelease: string = '20250121.000000'

	constructor() {
	}

	public initialize() {
		SubscriptionManager.onRelease(({minimumRequiredVersion}: ReleaseNotification) => {
			if (minimumRequiredVersion.localeCompare(ReleaseManagerClazz.CurrentRelease) < 0) {
				var secondsToWait = Math.ceil((Math.random() * 120));
				//console.log(`Reloading in ${secondsToWait} seconds...`);
				setTimeout(() => {
					window.location.reload();
				}, secondsToWait * 1000);
			} else {
				//console.log(`Running supported version.`);
			}
		})
	}
}

export const ReleaseManager = new ReleaseManagerClazz()