import $ from 'jquery'
import loggedInAccount from "loggedInAccount";
import layers from 'layers';
import _ from "underscore";
import misc from "misc";
import document from 'legacy/document.js';
import 'legacy/jquery.metadata';
import "legacy/jquery.qtip.js";
import "legacy/jquery.tipsy.js";


var parseTimeStudyInfo = function ($element) {
	var classes = $element.attr('class');
	var match = classes.match(/timeStudy_(\d+)-(\d+)-(\d+)/);
	if (match.length >= 4) {
		return {
			accountId: match[1],
			periodId: match[2],
			continuousMonth: match[3]
		}
	}

	return null;
};

var parseTimesheetInfo = function ($element) {
	var classes = $element.attr('class');
	var match = classes.match(/timesheet_(\d+)_(\d{4}-\d{2}-\d{2})_(\d{4}-\d{2}-\d{2})/);
	if (match.length >= 4) {
		return {
			accountId: match[1],
			startDate: match[2],
			endDate: match[3]
		}
	}

	return null;
};

$(document)
	.delegate("[title]", "mouseenter focus", function (event) {
		var $this = $(this);
		if ($this.is('.ui-datepicker-prev') || $this.is('.ui-datepicker-next') || $this.is('.select2-selection__choice') || $this.is('.select2-selection__rendered'))
			return;

		if ($this.is('.file-preview-frame'))
			return;
		if ($this.closest('.file-preview-frame').length != 0)
			return;

		var data = $this.metadata({type: 'attr', name: 'data'});
		var qtipOptions = data.qtipOptions;
		if (qtipOptions) {
			qtipOptions = $.extend(true, misc.getDefaultQtipOptions(), qtipOptions);

			if ($this.closest('.subMenu').length != 0) {
				if (qtipOptions.style && _.isObject(qtipOptions.style))
					qtipOptions.style['classes'] += " qtip-over-menu";
				else if (qtipOptions.style)
					qtipOptions.style += " qtip-over-menu";
			}

			if ($this.qtip('api'))
				return;
			$this.qtip(qtipOptions);
		} else {
			var tipsyOptions = data['tipsyOptions'];
			if (typeof (tipsyOptions) !== 'object') {
				tipsyOptions = {};
			}
			$this.tipsy($.extend(
				{
					gravity: 'n',
					opacity: 1
				},
				tipsyOptions
			));

			if (tipsyOptions.trigger == 'focus' && event.type.indexOf('focus') != -1) {
				$this.tipsy('show');
			} else if (tipsyOptions.trigger != 'focus' && event.type.indexOf('focus') == -1) {
				$this.tipsy('show');
			}
		}

	})
	.delegate("input.disabled", "click", function (event) {
		event.preventDefault();
	})
	.delegate('.clear-input', 'click', function () {
		var $this = $(this);
		var $input = $('#' + $this.attr('for'));
		$input.val('').trigger('input').focus();
	})
	.delegate('.cal', 'click', function () {
		var $this = $(this);
		var $input = $('#' + $this.attr('for'));
		if ($input.prop('disabled'))
			return;
		var datepickerData = $input.data('datepicker');
		if (datepickerData && _.isArray(datepickerData.dpDiv)) {
			var visible = $(datepickerData.dpDiv[0]).is(':visible');
			$input.datepicker(visible ? 'hide' : 'show');
		}
	})
	.on('click', '.blackout', function () {
		var closeButton = $('#close' + $(this).attr('id').substring(8)).filter(':visible');
		closeButton.click()
	})
	.on('click', 'SPAN.timesheet.pimg', function (event) {
		var $this = $(this);

		var timesheetInfo = parseTimesheetInfo($this);
		if (!timesheetInfo)
			return;

		document.location = '/mvc/Timesheet/' + timesheetInfo.accountId + "/" + timesheetInfo.startDate + "/" + timesheetInfo.endDate + loggedInAccount.extraQueryStringQM;
		event.stopPropagation();
	})
	.delegate('SPAN.timeStudy.pimg', 'click', function (event) {
		var $this = $(this);

		var timeStudyInfo = parseTimeStudyInfo($this);
		var accountId = timeStudyInfo.accountId;
		var periodId = timeStudyInfo.periodId;
		var continuousMonth = timeStudyInfo.continuousMonth;


		if (continuousMonth)
			document.location = '/edit-time-study.jsp?accountId=' + accountId + "&period=" + periodId + "&continuousMonth=" + continuousMonth + loggedInAccount.extraQueryStringAmp;
		event.stopPropagation();
	})
	.on('click', '.editTS', function (event) {
		var $this = $(this);
		var $pimg = $($this.find('.timeStudy.pimg').not('.eApproved').not('.ended').not('.beforeStartDate').toArray().concat($this.find('.timeStudy.pimg.eApproved').last().toArray())).first();
		if ($pimg && $pimg.length > 0) {
			$pimg.click();
		}
		event.stopPropagation();
	})
	.on('click', '.closeButton', function () {
		layers.removeTopLayer();
		return false;
	})
	.on('click', '.input-icon-wrap.datepicker', function () {
		var $div = $(this);
		var $input = $div.find('input[type=text]');

		if (!$input.is('.hasDatepicker')) {
			var attr = $input.data('datepicker');
			var options = $.extend({
				dateFormat: 'm/d/yy'

			}, attr ? attr : {});

			$input.datepicker(options);
			$input.datepicker('show');
		}
	})
	.on('click', '#sentEmailMessagesA', function () {
		var $sentEmailMessages = $('#sentEmailMessagesHtml');
		layers.center($sentEmailMessages);
		$sentEmailMessages.toggle();
	})
	.on('beforeProcessing.layers beforeLoading.layers', function () {
		$('[data-hasqtip]').each(function () {
			var api = $(this).qtip('api');
			if (api) {
				api.destroy(true);
			}
		});
	})
	.on('select2:select select2:unselect', 'select', function () {
		$(window).resize();
	})
	.on('select2:select', 'select', function () {
		$(this).focus();
	});