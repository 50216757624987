import "global/polyfills"
import "global/common.js"
import loaded from "global/loaded.js";
import {ErrorHandler} from "global/error";
import {SsoRefreshScheduler} from "global/sso-refresh-scheduler"

import {defineLoggedInAccount, LoggedInAccount} from "loggedInAccount";
import {County, defineCounty} from "county";
import {CountyPeriod, defineCountyPeriod} from "countyPeriod";
import {Counties, defineCounties} from "counties";
import {defineDateHelpers} from "dateHelpers";
import {ReleaseManager} from "global/utils/ReleaseManager";

function initialize(data) {
	ErrorHandler.initialize()

	defineCounty(data.county)
	defineCountyPeriod(data.countyPeriod)
	defineCounties(data.counties)
	defineLoggedInAccount(data.loggedInAccount)
	defineDateHelpers(data.dateHelpers);

	if (LoggedInAccount && LoggedInAccount.sessionExpires !== null && LoggedInAccount.ssoCode != null) {
		SsoRefreshScheduler.schedule();
	}

	ReleaseManager.initialize();
	loaded.pageLoaded()
}

function isDynamicImportNotFoundError(error: Error) {
	return error && error.message.indexOf("Unknown variable dynamic import:") !== -1;
}

export function loadPage(page: string) {
	return import(`../../pages/${page}/${page}.js`).catch((error: Error) => {
		if (isDynamicImportNotFoundError(error)) {
			return import(`../../pages/${page}/${page}.ts`).catch((error: Error) => {
				if (isDynamicImportNotFoundError(error)) {
					throw Error(`could not find /js/pages/${page}/${page}.js or /js/pages/${page}/${page}.ts`)
				} else {
					throw error;
				}
			});
		} else {
			throw error;
		}
	})
}

export function loadModule(moduleId: string) {
	return import(`../../modules/${moduleId}/${moduleId}.js`).catch((error: Error) => {
		if (isDynamicImportNotFoundError(error)) {
			return import(`../../modules/${moduleId}/${moduleId}.ts`).catch((error: Error) => {
				if (isDynamicImportNotFoundError(error)) {
					throw Error(`could not find /js/modules/${moduleId}/${moduleId}.js or /js/modules/${moduleId}/${moduleId}.ts`)
				} else {
					throw error;
				}
			});
		} else {
			throw error;
		}
	})
}

export function loadHelper(moduleId: string) {
	return import(`../../helpers/${moduleId}/${moduleId}.js`).catch((error: Error) => {
		if (isDynamicImportNotFoundError(error)) {
			return import(`../../helpers/${moduleId}/${moduleId}.ts`).catch((error: Error) => {
				if (isDynamicImportNotFoundError(error)) {
					throw Error(`could not find at /js/helpers/${moduleId}/${moduleId}.js or /js/helpers/${moduleId}/${moduleId}.ts`)
				} else {
					throw error;
				}
			});
		} else {
			throw error;
		}
	})
}


export const bootstrap = {
	County: County,
	LoggedInAccount: LoggedInAccount,
	CountyPeriod: CountyPeriod,
	Counties: Counties,
	initialize: initialize,
	loadPage: loadPage,
	loadModule: loadModule,
	loadHelper: loadHelper,
}
export default bootstrap;

window["bootstrap"] = bootstrap;