import $ from "jquery";
import domReady from 'legacy/domReady.js';

domReady(function () {
	var $window = $(window);
	var $bodyWrap = $('#bodyWrap');
	var $bodyScroll = $('#bodyScroll');

	if ($bodyScroll.length > 0) {
		$(window).bind('resize', resizeBodyScroll).resize();
	} else {
		$(window).bind('resize', resizeBodyWrap).resize();
	}

	function resizeBodyWrap() {
		$bodyWrap.css({
			overflow: 'auto',
			height: $window.height(),
			width: $window.width()
		});
	}

	function resizeBodyScroll() {
		$bodyScroll.css({
			overflow: 'auto',
			height: $window.height() - $bodyScroll.offset().top,
			width: $window.width()
		});
	}
});