import moment from "moment-timezone";
import _ from 'underscore';


class DateHelpers {
	constructor(data) {
		if (data)
			load(data);
	}

	load(data) {


		var self = this;
		_.extend(self, {
			now: moment.parseZone(data.now),
			nowUTC: moment.utc(data.utc),
			today: function () {
				return self.now.clone().startOf('day').utcOffset(0, true)
			}
		});
	}
}

var self = new DateHelpers();

export function defineDateHelpers(data) {
	if (data)
		self.load(data);
}

export default self;